import type { GetStaticProps, InferGetStaticPropsType } from 'next';
import type { ParsedUrlQuery } from 'querystring';
import { NextSeo } from 'next-seo';

import {
  frontpageQuery,
  getNavigation,
  latestFrontpageIdQuery,
  populateFrontpageWithProductUrls,
} from '@/lib/sanity/queries';
import type { MaterializedFrontpage } from '@/lib/sanity/queries';
import { usePreviewSubscribtion } from '@/lib/sanity/hooks';
import Debug from '@/components/debug';
import { ContentBlocksRenderer } from '@/components/contentBlocks/ContentBlocksRenderer';
import {
  configuredSanityClientWithoutCdn,
  sanityServerClient,
} from '@/lib/sanity/sanityClient';

export type FrontpageProps = InferGetStaticPropsType<typeof getStaticProps>;

export const Frontpage = (props: FrontpageProps) => {
  const {
    frontpage: initialFrontpage,
    preview = false,
    previewData,
    categories,
  } = props;
  const id = previewData?.id || initialFrontpage._id;

  const frontpage = usePreviewSubscribtion<MaterializedFrontpage>({
    query: frontpageQuery,
    queryParams: { id },
    preview,
    previewData,
    initialData: initialFrontpage,
    // transformData: populateFrontpageWithImageUrls,
  });

  const { blocks } = frontpage;
  return (
    <>
      <NextSeo description="Møbler - Høy kvalitet, gode priser" />
      <main className="-mt-2 [&>[data-type=ContentBlocksGrid]]:mt-5 [&>:not([data-type=ContentBlocksGrid])]:mt-20 [&>:not([data-type=ContentBlocksGrid])]:mb-20">
        <ContentBlocksRenderer blocks={blocks} />
        <Debug data={{ preview, previewData }} label="Preview" />
        <Debug data={frontpage} label="Frontpage" />
        <Debug data={initialFrontpage} label="Initial Frontpage" />
        <Debug data={categories} label="categories" />
      </main>
    </>
  );
};

export const getStaticProps: GetStaticProps<
  any,
  ParsedUrlQuery,
  { id: string }
> = async (context) => {
  const { preview = false, previewData = null } = context;
  const frontpageId = await configuredSanityClientWithoutCdn.fetch<String>(
    latestFrontpageIdQuery
  );

  const frontpage = await sanityServerClient.fetch<MaterializedFrontpage>(
    frontpageQuery,
    {
      id: process.env.FRONTPAGE_ID || frontpageId,
    }
  );

  // todo, populate products from productCollectionBlocks

  const navigation = await getNavigation();

  return {
    props: {
      frontpage: await populateFrontpageWithProductUrls(frontpage),
      navigation,
      preview,
      previewData,
    },
    revalidate: 60,
  };
};

export default Frontpage;
