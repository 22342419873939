import { useCallback, useEffect, useRef } from 'react';
import { configuredSanityClientWithCredentials } from './sanityClient';
import { useState } from 'react';
import debounce from 'just-debounce';
import groq from 'groq';
import { Params } from '@sanity/preview-kit';

const generalPreviewQuery = groq`*[_id == $id][0]`;

export const usePreviewSubscribtion = <T>(props: {
  query?: string;
  queryParams: Params;
  preview: boolean;
  previewData: any;
  initialData: T;
  transformData?(data: T): Promise<T | null>;
}) => {
  const {
    query = generalPreviewQuery,
    queryParams,
    preview = false,
    previewData = {},
    initialData,
    transformData,
  } = props;

  const [data, setData] = useState<T | null>(initialData);
  useEffect(() => {
    const fetchData = debounce(() => {
      // console.log('FetchData', { data, query, queryParams, transformData });
      configuredSanityClientWithCredentials
        .fetch<T>(query, queryParams)
        .then((freshData) => {
          if (JSON.stringify(data) !== JSON.stringify(freshData)) {
            if (transformData) {
              transformData(freshData).then((transformedData) => {
                return setData(transformedData);
              });
            }
            setData(freshData);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 1000);

    const handleMutation = () => {
      fetchData();
    };
    if (!preview) {
      return;
    }
    if (!previewData) {
      console.error('no preview data');
      return;
    }

    console.info('Init preview', previewData);
    fetchData();

    const subscribtion = configuredSanityClientWithCredentials
      .listen<T | any>(generalPreviewQuery, queryParams, {
        includeResult: false,
        events: ['mutation'],
      })
      .subscribe((data) => {
        if (data.type === 'mutation') {
          return handleMutation();
        }
        if (data.type === 'channelError') {
          console.error('channelError', data);
          subscribtion.unsubscribe();
        }
        console.error('Unexpected data type', data);
      });

    return () => {
      subscribtion.unsubscribe();
    };
  }, []);

  return data || initialData;
};
